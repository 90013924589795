// // src/components/PrivateRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem('user_id');
  console.log('PrivateRoute: isAuthenticated =', isAuthenticated);

  // Ensure the user_id is valid (not null, undefined, or an empty string)
  if (!isAuthenticated || isAuthenticated === 'null' || isAuthenticated === 'undefined') {
    console.log('PrivateRoute: Redirecting to login page');
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default PrivateRoute;
