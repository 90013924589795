// // src/components/LoginPage.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(''); // Clear error on retry

    try {
      const response = await fetch(`${API_BASE_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('user_id', data.user_id);
        localStorage.setItem('username', data.username);
        localStorage.setItem('access_level', data.access_level);
        localStorage.setItem('email', data.email);

        navigate('/'); // Navigate to home on success
      } else {
        setError('Invalid email or password');
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <div
      className="h-screen flex flex-col items-center justify-center text-center"
      style={{ backgroundColor: '#ffe5f6' }}
    >
      <h1 className="text-black text-4xl font-bold mb-8">Login</h1>
      <div className="absolute top-0 left-0 z-20 ml-2 flex items-center">
        <img
          src="https://storage.googleapis.com/rgw-public/logo-02.png"
          alt="Company Logo"
          className="h-20 mr-4"
        />
      </div>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <form
        onSubmit={handleLogin}
        className="bg-white bg-opacity-30 p-8 rounded-lg shadow-lg border-2 border-[#A5E5C6] hover:border-pink-600 transition-colors duration-200"
      >
        <div className="mb-4">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Apna Email Daalo"
            className="p-3 w-full bg-pink-50 text-black border-2 border-[#A5E5C6] rounded-lg focus:outline-none focus:border-pink-800 shadow-lg hover:border-pink-600"
            required
          />
        </div>
        <div className="mb-6">
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Apna Password Daalo"
            className="p-3 w-full bg-pink-50 text-black border-2 border-[#A5E5C6] rounded-lg focus:outline-none focus:border-pink-800 shadow-lg hover:border-pink-600"
            required
          />
        </div>
        <button
          type="submit"
          className="w-full bg-[#D9008D] text-white py-3 rounded-lg hover:bg-white hover:text-[#D9008D] border border-[#D9008D] shadow-lg transition duration-300"
        >
          Login
        </button>
      </form>
    </div>
  );
};

export default LoginPage;
