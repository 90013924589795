// src/App.js
import { Route, Routes } from 'react-router-dom';
import ChatbotLayout from './components/ChatbotLayout';
import CreateChatbot from './components/CreateChatbot';
import ModifyChatbot from './components/ModifyChatbot';
import LoginPage from './components/LoginPage';
import PrivateRoute from './components/PrivateRoute';
import UnifiedChat from './components/UnifiedChat';

function App() {
  
  return (
    <Routes>
      {/* Public Route */}
      <Route path="/login" element={<LoginPage />} />

      {/* Private Routes */}
      <Route
        path="/"
        element={
          <PrivateRoute>
            <ChatbotLayout />
          </PrivateRoute>
        }
      >
        {/* Nested Routes */}
        <Route path="chat/:id" element={<UnifiedChat />} />
        <Route path="modify-chatbot/:id" element={<ModifyChatbot />} />
        <Route path="create-chatbot" element={<CreateChatbot />} />
        <Route
          index
          element={
            <div className="flex items-center justify-center h-full text-center">
              <h1 className="text-4xl font-bold text-[#D9008D] mb-4">
                Apne Clone ko select Kare
              </h1>
            </div>
          }
        />
      </Route>
    </Routes>
  );
}

export default App;
