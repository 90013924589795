

// // // src/components/ChatbotSidebar.js

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config';
import { FaEllipsisV } from 'react-icons/fa';

function ChatbotSidebar({ setActiveChatbot }) {
  const [chatbots, setChatbots] = useState([]);
  const [showOptions, setShowOptions] = useState(null);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Fetch chatbots associated with the user
    const fetchChatbots = async () => {
      const email = localStorage.getItem('email');
      if (!email) {
        console.error('No email found in localStorage');
        return;
      }

      try {
        const response = await fetch(`${API_BASE_URL}/chatbots?email=${email}`);
        const data = await response.json();
        if (response.ok) {
          setChatbots(data);
        } else {
          console.error('No chatbots found for this user');
          setChatbots([]);
        }
      } catch (error) {
        console.error('Error fetching chatbots:', error);
      }
    };

    fetchChatbots();
  }, []);

  // Close dropdown on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleChatbotClick = (chatbot) => {
    // Set the active chatbot for display
    if (setActiveChatbot) {
      setActiveChatbot(chatbot); // Update parent state with the selected chatbot
    }
    handleNavigate(`/chat/${chatbot.id}`);
  };

  const handleModifyClick = (chatbotId) => {
    console.log('Modify button clicked for ID:', chatbotId);
    handleNavigate(`/modify-chatbot/${chatbotId}`);
  };

  const handleCreateChatbot = () => {
    console.log('Create Chatbot button clicked');
    handleNavigate('/create-chatbot');
  };

  const toggleOptions = (chatbotId) => {
    setShowOptions((prev) => (prev === chatbotId ? null : chatbotId));
  };

  return (
    <div className="w-64 bg-[#2E3B55] text-white flex flex-col h-full">
      {/* Sidebar Header */}
      <div className="p-4 flex items-center">
        <img
          src="https://storage.googleapis.com/rgw-public/logo-02.png"
          alt="Company Logo"
          className="h-12 mr-2"
        />
      </div>

      {/* Chatbot List */}
      <div className="flex-1 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-900">
        {chatbots.map((chatbot) => (
          <div key={chatbot.id} className="relative group">
            <div
              className="flex items-center px-4 py-2 hover:bg-gray-800 cursor-pointer"
              onClick={() => handleChatbotClick(chatbot)}
            >
              <span className="flex-1 truncate font-bold">
                {chatbot.chatbot_name || 'Untitled Chatbot'}
              </span>
              <button
                className="text-gray-400 hover:text-white"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleOptions(chatbot.id);
                }}
              >
                <FaEllipsisV />
              </button>
            </div>

            {showOptions === chatbot.id && (
              <div
                ref={dropdownRef}
                className="absolute right-4 mt-1 bg-white text-black rounded shadow-lg z-50"
                style={{ minWidth: '100px' }}
              >
                <button
                  className="block px-3 py-1 text-sm hover:bg-gray-200 text-center rounded-sm"
                  onClick={() => handleModifyClick(chatbot.id)}
                >
                  Modify
                </button>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Footer Buttons */}
      <div className="p-4 border-t border-gray-700 space-y-2">
        <button
          className="w-full bg-[#D9008D] hover:bg-white hover:text-[#D9008D] text-white py-2 px-4 rounded-lg border-2 border-[#D9008D] transition duration-300 ease-in-out font-bold shadow-lg"
          onClick={handleCreateChatbot}
        >
          Create Chatbot
        </button>
      </div>
    </div>
  );
}

export default ChatbotSidebar;
