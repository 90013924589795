

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config';

function CreateChatbot() {
  const [chatbotName, setChatbotName] = useState('');
  const [userName, setUserName] = useState('');
  const [content, setContent] = useState('');
  const [chatbotTitle, setChatbotTitle] = useState('');
  const [file, setFile] = useState(null);
  const [isFileSelected, setIsFileSelected] = useState(false);

  const navigate = useNavigate();


  // Handle file upload (PDF or DOC)
  const handleFileUpload = (e) => {
    setFile(e.target.files[0]);
    setIsFileSelected(true);
    setContent(''); // Clear content if file is selected
  };

  // Handle content text input (if not using a file)
  const handleContentChange = (e) => {
    setContent(e.target.value);
    setIsFileSelected(false); // Unselect file if content is being typed
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('chatbot_title', chatbotTitle);
    formData.append('chatbot_name', chatbotName);
    formData.append('user_name', userName);
    // Capture email from localStorage without setting it in the component state
    const accessLevel = localStorage.getItem('email');  // Retrieve the email-like value
    if (accessLevel) {
      formData.append('email', accessLevel); // Append access_level as email to formData
    }
    
    
    // Check if file is uploaded or if content is manually provided
    if (isFileSelected && file) {
      formData.append('file', file);  // Send file (PDF or DOC) to backend
    } else {
      formData.append('content', content);  // Send manual content to backend
    }

    
    try {
      const response = await fetch(`${API_BASE_URL}/create-chatbot`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        alert('Chatbot created successfully!');
        navigate('/');
      } else {
        alert('Failed to create chatbot.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="h-screen bg-[#ffe5f6] flex flex-col items-center justify-center text-center">
      <h1 className="text-black text-4xl font-bold mb-8">naya Clone Banao</h1>

      <form onSubmit={handleSubmit} className="w-full max-w-sm">
        <div className="mb-6">
          <label className="block text-gray-700 text-left mb-1 text-sm">Clone Ka Title</label>
          <input
            type="text"
            placeholder="Chai Pe Charcha 😎"
            value={chatbotTitle}
            onChange={(e) => setChatbotTitle(e.target.value)}
            className="bg-pink-50 text-black border border-[#A5E5C6] p-2 rounded-lg w-full max-w-md focus:border-pink-800 focus:outline-none placeholder:text-sm hover:bg-pink-100 hover:border-pink-600"
            required
          />
        </div>

        <div className="mb-6">
          <label className="block text-gray-700 text-left mb-1 text-sm">Clone Ka Naam</label>
          <input
            type="text"
            placeholder="Sajal Ka Dimag 🧠"
            value={chatbotName}
            onChange={(e) => setChatbotName(e.target.value)}
            className="bg-pink-50 text-black border border-[#A5E5C6] p-2 rounded-lg w-full max-w-md focus:border-pink-800 focus:outline-none placeholder:text-sm hover:bg-pink-100 hover:border-pink-600"
            required
          />
        </div>

        <div className="mb-6">
          <label className="block text-gray-700 text-left mb-1 text-sm">User Ka Naam</label>
          <input
            type="text"
            placeholder="customer saab 🕶️"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            className="bg-pink-50 text-black border border-[#A5E5C6] p-2 rounded-lg w-full max-w-md focus:border-pink-800 focus:outline-none placeholder:text-sm hover:bg-pink-100 hover:border-pink-600"
            required
          />
        </div>

        <div className="mb-6">
          <label className="block text-gray-700 text-left mb-1 text-sm">Upload PDF or DOC</label>
          <input
            type="file"
            accept=".pdf,.doc,.docx"
            onChange={handleFileUpload}
            className="bg-pink-50 text-black border border-[#A5E5C6] p-2 rounded-lg w-full max-w-md focus:border-pink-800 focus:outline-none placeholder:text-sm hover:bg-pink-100 hover:border-pink-600"
          />
          <small className="text-gray-500">You can either upload a PDF/DOC file or enter text content below.</small>
        </div>

        <div className="mb-6">
          <label className="block text-gray-700 text-left mb-1 text-sm">Or Enter Text Content</label>
          <textarea
            placeholder="Help guide the user on these FAQs: (List your FAQs)"
            value={content}
            onChange={handleContentChange}
            className={`bg-pink-50 text-black border border-[#A5E5C6] p-2 rounded-lg w-full max-w-md focus:border-pink-800 focus:outline-none placeholder:text-sm hover:bg-pink-100 hover:border-pink-600 ${
              isFileSelected ? 'bg-gray-200' : ''
            }`}
            disabled={isFileSelected} // Disable text input if file is selected
          ></textarea>
        </div>

        <button
          type="submit"
          className="bg-[#D9008D] text-white px-6 py-2 rounded-xl hover:bg-white hover:text-[#D9008D] border border-[#D9008D] shadow-lg transition duration-300"
        >
          Create Clone
        </button>
      </form>
    </div>
  );
}

export default CreateChatbot;
