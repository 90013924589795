
// // src/components/ChatbotLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import ChatbotSidebar from './ChatbotSidebar';

function ChatbotLayout() {
  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <ChatbotSidebar />

      {/* Main Content */}
      <div className="flex-1 overflow-auto bg-gray-900 text-white">
        <Outlet />
      </div>
    </div>
  );
}

export default ChatbotLayout;
